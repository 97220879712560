export const ROLES = {
  SUPER_ADMIN_ORG: '5b7ed2fd4adf6bb06181f1d6',
  ADMIN_ORG: '5a282dffc0844f13cb8a7be9',
  USER: '5a282dffc0844f13cb8a7be7',
  USER_ORG: '5a282dffc0844f13cb8a7be8',
  SUPER_ADMIN_GAS_STATION: '5b7ed25d4adf6bb06181f1ca',
  ADMIN_GAS_STATION: '5a282dffc0844f13cb8a7beb',
  USER_GAS_STATION: '5a282dffc0844f13cb8a7bea',
  ADMIN_INTERNAL_TANK: '658c36e580de86eeef4a8465',
  USER_INTERNAL_TANK: '658c36e580de86eeef4a8466',
  ROOT: '5a282a107201c72e5f36b7d6',
};

export const CATEGORY_NAME = {
  [ROLES.USER]: 'contractor',
  [ROLES.USER_ORG]: 'employee',
  [ROLES.ADMIN_ORG]: 'administrator',
};

export const ROLES_TYPE = [
  { label: 'SuperAdminOrg', value: ROLES.SUPER_ADMIN_ORG, key: 0 },
  { label: 'AdminOrg', value: ROLES.ADMIN_ORG, key: 1 },
  { label: 'UserOrg', value: ROLES.USER_ORG, key: 2 },
  { label: 'User', value: ROLES.USER, key: 3 },
];

export const INTERNAL_TANK_ROLES_TYPE = [
  { label: 'UserInternalTank', value: ROLES.USER_INTERNAL_TANK, key: 0 },
  { label: 'AdminInternalTank', value: ROLES.ADMIN_INTERNAL_TANK, key: 1 },
];

export const PERMISSION_ROLES_TYPES = [
  { label: 'Root', value: ROLES.ROOT, key: 0 },
  { label: 'AdminOrg', value: ROLES.ADMIN_ORG, key: 1 },
  { label: 'SuperAdminOrg', value: ROLES.SUPER_ADMIN_ORG, key: 2 },
  { label: 'AdminGasStation', value: ROLES.ADMIN_GAS_STATION, key: 3 },
  {
    label: 'SuperAdminGasStation',
    value: ROLES.SUPER_ADMIN_GAS_STATION,
    key: 4,
  },
];

export const STATION_ROLES_TYPE = [
  {
    label: 'SuperAdminGasStation',
    value: ROLES.SUPER_ADMIN_GAS_STATION,
    key: 0,
  },
  { label: 'AdminGasStation', value: ROLES.ADMIN_GAS_STATION, key: 1 },
  { label: 'UserGasStation', value: ROLES.USER_GAS_STATION, key: 2 },
];

export const BALANCE_SHARING_INPUTS = [
  {
    label: 'input.employee',
    id: 'tankEmployee',
    defaultChecked: true,
    value: 'employee',
  },
  {
    label: 'input.vehicle',
    id: 'tankVehicle',
    defaultChecked: false,
    value: 'vehicle',
  },
];

export const TANK_BALANCE_SHARING_INPUTS = [
  {
    label: 'input.employee',
    id: 'employee',
    defaultChecked: true,
    value: 'employee',
  },
  {
    label: 'input.vehicle',
    id: 'vehicle',
    defaultChecked: false,
    value: 'vehicle',
  },
];

export const ACRONYM_STATES = [
  { label: 'Acre', value: 'AC', key: 0 },
  { label: 'Alagoas', value: 'AL', key: 1 },
  { label: 'Amapá', value: 'AP', key: 2 },
  { label: 'Amazonas', value: 'AM', key: 3 },
  { label: 'Bahia', value: 'BA', key: 3 },
  { label: 'Ceará', value: 'CE', key: 4 },
  { label: 'Distrito Federal', value: 'DF', key: 5 },
  { label: 'Espírito Santo', value: 'ES', key: 6 },
  { label: 'Goiás', value: 'GO', key: 7 },
  { label: 'Maranhão', value: 'MA', key: 8 },
  { label: 'Mato Grosso', value: 'MT', key: 9 },
  { label: 'Mato Grosso do Sul', value: 'MS', key: 10 },
  { label: 'Minas Gerais', value: 'MG', key: 11 },
  { label: 'Pará', value: 'PA', key: 12 },
  { label: 'Paraíba', value: 'PB', key: 13 },
  { label: 'Paraná', value: 'PR', key: 14 },
  { label: 'Pernambuco', value: 'PE', key: 15 },
  { label: 'Piauí', value: 'PI', key: 16 },
  { label: 'Rio de Janeiro', value: 'RJ', key: 17 },
  { label: 'Rio Grande do Norte', value: 'RN', key: 18 },
  { label: 'Rio Grande do Sul', value: 'RS', key: 19 },
  { label: 'Rondônia', value: 'RO', key: 20 },
  { label: 'Roraima', value: 'RR', key: 21 },
  { label: 'Santa Catarina', value: 'SC', key: 22 },
  { label: 'São Paulo', value: 'SP', key: 23 },
  { label: 'Sergipe', value: 'SE', key: 24 },
  { label: 'Tocantins', value: 'TO', key: 25 },
];

export const PERMISSIONS = {
  ENTRIES: {
    ADMIN: {
      LIST: 'com.litroz.portalAdmin.admin.list',
      ADD: 'com.litroz.portalAdmin.admin.add',
      REMOVE: 'com.litroz.portalAdmin.admin.remove',
    },
    ORGANIZATION: {
      LIST: 'com.litroz.portalAdmin.organization.list',
      ADD: 'com.litroz.portalAdmin.organization.add',
      REMOVE: 'com.litroz.portalAdmin.organization.remove',
    },
    STATIONS: {
      LIST: 'com.litroz.portalAdmin.gasStation.list',
      ADD: 'com.litroz.portalAdmin.gasStation.add',
      REMOVE: 'com.litroz.portalAdmin.gasStation.remove',
    },
    STATION_USERS: {
      LIST: 'com.litroz.portalAdmin.gasStationUser.list',
      ADD: 'com.litroz.portalAdmin.gasStationUser.add',
      REMOVE: 'com.litroz.portalAdmin.gasStationUser.remove',
    },
    USER: {
      LIST: 'com.litroz.portalAdmin.user.list',
      ADD: 'com.litroz.portalAdmin.user.add',
      REMOVE: 'com.litroz.portalAdmin.user.remove',
    },
    VEHICLE: {
      LIST: 'com.litroz.portalAdmin.vehicle.list',
      ADD: 'com.litroz.portalAdmin.vehicle.add',
      REMOVE: 'com.litroz.portalAdmin.vehicle.remove',
    },
    DISCOUNT_RATE: {
      LIST: 'com.litroz.portalAdmin.discountRate.list',
      ADD: 'com.litroz.portalAdmin.discountRate.add',
    },
    CONTAINER: {
      LIST: 'com.litroz.portalAdmin.container.list',
      ADD: 'com.litroz.portalAdmin.container.add',
      REMOVE: 'com.litroz.portalAdmin.container.remove',
    }
  },
  SECURITY: {
    PERMISSIONS: {
      LIST: 'com.litroz.portalAdmin.permissions.list',
      ADD: 'com.litroz.portalAdmin.permissions.add',
      REMOVE: 'com.litroz.portalAdmin.permissions.remove',
    },
    USER_PERMISSIONS: {
      LIST: 'com.litroz.portalAdmin.userPermissions.list',
      ADD: 'com.litroz.portalAdmin.userPermissions.add',
    },
  },
  REPORTS: {
    BILLING_STATEMENT: {
      LIST: 'com.litroz.portalAdmin.billingStatement.list',
    },
    BILLS: {
      LIST: 'com.litroz.portalAdmin.bills.list',
    },
    BALANCE_SHARING: {
      LIST: 'com.litroz.portalAdmin.balanceSharing.list',
    },
  },
  DEPOSITS: {
    LIST: 'com.litroz.portalAdmin.deposit.list',
    REMOVE: 'com.litroz.portalAdmin.deposit.remove',
    ATTACHMENTS: {
      LIST: 'com.litroz.portalAdmin.depositAttachments.list',
      ADD: 'com.litroz.portalAdmin.depositAttachments.add',
      REMOVE: 'com.litroz.portalAdmin.depositAttachments.remove',
    },
  },
  MONEY_TRANSFER: {
    LIST: 'com.litroz.portalAdmin.moneyTransfer.list',
  },
  GAS_STATIONS_BILLINGS: {
    LIST: 'com.litroz.portalAdmin.gasStationBilling.list',
  },
  ORGANIZATION_INVOICES: {
    LIST: 'com.litroz.portalAdmin.organizationInvoice.list',
  },
  TRANSACTIONS: {
    LIST: 'com.litroz.portalAdmin.transaction.list',
    ADD: 'com.litroz.portalAdmin.transaction.add',
    REMOVE: 'com.litroz.portalAdmin.transaction.remove',
  },
  INTERNAL_TANK: {
    TANKS: {
      LIST: 'com.litroz.portalAdmin.internalTank.list',
      ADD: 'com.litroz.portalAdmin.internalTank.add',
      REMOVE: 'com.litroz.portalAdmin.internalTank.remove',
    },
    USERS: {
      LIST: 'com.litroz.portalAdmin.internalTankUser.list',
      ADD: 'com.litroz.portalAdmin.internalTankUser.add',
      REMOVE: 'com.litroz.portalAdmin.internalTankUser.remove',
    },
    TRANSACTIONS: {
      LIST: 'com.litroz.portalAdmin.internalTankTransaction.list',
    },
  },
  PAYMENT_AUTHORIZATION: {
    LIST: 'com.litroz.portalAdmin.paymentAuthorization.list',
    ADD: 'com.litroz.portalAdmin.paymentAuthorization.add',
    REMOVE: 'com.litroz.portalAdmin.paymentAuthorization.remove',
  },
};

export const PATH = {
  ENTRIES: {
    ADMIN: '/app/entries/admins',
    ORGANIZATION: '/app/entries/organizations',
    STATIONS: '/app/entries/stations',
    STATION_USERS: '/app/entries/stationUsers',
    USER: '/app/entries/user',
    VEHICLE: '/app/entries/vehicles',
    DISCOUNT_RATE: '/app/entries/discountRate',
  },
  SECURITY: {
    PERMISSIONS: '/app/security/permissions',
    USER_PERMISSIONS: '/app/security/users',
  },
  REPORTS: {
    BILLING_STATEMENT: '/app/reports/billing-statement',
    BILLS: '/app/reports/bills',
    BALANCE_SHARING: '/app/reports/balance-sharing',
  },
  DEPOSITS: '/app/deposits',
  DEPOSIT_ATTACHMENTS: 'app/deposits/attachments',
  MONEY_TRANSFER: '/app/money-transfer',
  INTERNAL_TANK: {
    TANKS: '/app/fuel-management/tanks',
    USERS: '/app/fuel-management/users',
    TRANSACTIONS: '/app/fuel-management/transactions',
  },
  TRANSACTIONS: '/app/transactions',
  GAS_STATIONS_BILLINGS: '/app/gas-station-billing',
  ORGANIZATION_INVOICES: '/app/invoices',
  PAYMENT_AUTHORIZATION: '/app/payment-authorization',
};
