import { APIParams } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { VEHICLE_TYPE } from 'constants/defaultValues';
import * as vehicles from './actions';

function* list(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params, type: VEHICLE_TYPE.VEHICLE }));

  if (response.ok) {
    const vehicleList = response.data.docs;

    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(vehicles.onSuccess({ list: vehicleList, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(vehicles.onError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.post, {
    ...payload.vehicles,
  });

  if (response.ok) {
    yield put(vehicles.getVehicles(payload.params));
  } else {
    const message = 'Não foi possível criar o veículo';
    yield put(vehicles.onError(message));
  }
}

function* edit(api, { payload }) {
  const response = yield call(api.put, payload.id, payload.vehicle);

  if (response.ok) {
    yield put(vehicles.getVehicles(payload.params));
  } else {
    const message = 'Não foi possível editar o veículo';
    yield put(vehicles.onError(message));
  }
}

function* remove(api, { payload }) {
  const response = yield call(api.remove, payload.vehicle);

  if (response.ok) {
    yield put(vehicles.getVehicles(payload.params));
  } else {
    const message = 'Não foi possível remover o veículo';
    yield put(vehicles.onError(message));
  }
}

function* undelete(api, { payload }) {
  const response = yield call(api.undelete, payload.id);

  if (response.ok) {
    yield put(vehicles.getVehicles(payload.params));
  } else {
    const message = 'Não foi possível recuperar o veículo';
    yield put(vehicles.onError(message));
  }
}

function* watchList(api) {
  yield takeEvery(vehicles.ACTIONS.GET_VEHICLE, list, api);
}

function* watchPost(api) {
  yield takeEvery(vehicles.ACTIONS.CREATE_VEHICLE, post, api);
}

function* watchPut(api) {
  yield takeEvery(vehicles.ACTIONS.EDIT_VEHICLE, edit, api);
}

function* watchDelete(api) {
  yield takeEvery(vehicles.ACTIONS.DELETE_VEHICLE, remove, api);
}

function* watchUndelete(api) {
  yield takeEvery(vehicles.ACTIONS.UNDELETE_VEHICLE, undelete, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchList, api),
    fork(watchPost, api),
    fork(watchPut, api),
    fork(watchDelete, api),
    fork(watchUndelete, api),
  ]);
}
