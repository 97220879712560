import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const listContainer = parameters =>
  api.get(`/admin/vehicle${objectToURLParams(parameters)}`);

export const registerContainer = payload =>
  api.post(`/admin/organization/vehicle`, payload);

export const editContainer = (id, payload) => api.put(`/vehicle/${id}`, payload);

export const deleteContainer = id => api.delete(`/admin/vehicle/${id}`);

export const undeleteContainer = id => api.put(`/vehicle/undelete/${id}`);
